import { format, parseISO } from 'date-fns';

export function toApiPayloadDateFormat(
  date: string | Date | null,
  dateFormat: string = 'yyyy-MM-dd',
) {
  if (!date) {
    return '';
  }

  try {
    return format(new Date(date), dateFormat);
  } catch (error) {
    return '';
  }
}

/**
 * Transforms a UTC date string into a local Date object, effectively treating it as a date without a timezone.
 *
 * Steps:
 * 1. Converts the input UTC date string into a Date object. This initial Date object is in the UTC time zone.
 * 2. Converts this UTC Date object to an ISO string, which is also in UTC.
 * 3. Removes the 'Z' character from the end of the ISO string, which indicates UTC time zone.
 *    This results in a time-zone-agnostic string.
 * 4. Creates a new Date object from this modified string, which is then interpreted in the local time zone.
 *
 * @param {string} utcString - The UTC date string to be converted.
 * @returns {Date} - The resulting local Date object.
 */
export function transformUTCStringToLocalDate(utcString: string | Date): Date {
  // Step 1: Convert the UTC string to a Date object (UTC Date)
  const utcDate = new Date(utcString);

  // Step 2: Convert the UTC Date object to an ISO string (still in UTC)
  const isoString = utcDate.toISOString();

  // Step 3: Remove the 'Z' character to make the string time-zone-agnostic
  const timeZoneAgnosticString = isoString.slice(0, -1);

  // Step 4: Create a new Date object using the modified string (interpreted as local time)
  const localDate = new Date(timeZoneAgnosticString);

  return localDate;
}

/**
 * Transforms a UTC date into a local Date object, effectively treating it as a date without a timezone.
 * @param {Date} utcDate - The UTC date  to be converted.
 * @returns {Date} - The resulting local Date object.
 */
export function transformUTCDateToLocalDate(utcString: Date): Date {
  const isoString = utcString.toISOString();
  const localDate = new Date(isoString);
  return localDate;
}

/**
 * Converts a UTC string received from the API to a local date object for display.
 */
export function convertUTCToLocal(
  utcString?: string | Date,
  shouldFormat?: boolean,
  onlyDate?: boolean,
  dateFormat?: string,
): Date | string {
  try {
    if (!utcString) {
      return '-';
    }
    const localDate =
      utcString instanceof Date
        ? transformUTCDateToLocalDate(utcString)
        : transformUTCStringToLocalDate(utcString);

    if (shouldFormat) {
      const defaultDateFormat = onlyDate ? 'MM/dd/yyyy' : 'MM/dd/yyyy hh:mm aa';
      const finalDateFormat = dateFormat || defaultDateFormat;

      return format(localDate, finalDateFormat);
    }

    return localDate;
  } catch (error) {
    return '';
  }
}
